import { addEditableTags } from "@contentstack/utils";
import { AxiosRequestHeaders } from "axios";
import { SplashData } from "components/Splash";
import { ContentTypeUid } from "constants/contentTypeUid.enum";
import {
  CMSUrlData,
  Environment,
  FindEnvironment,
  IPageConfig,
} from "constants/pages.constants";
import {
  PRODUCT_DETAILS_REDUCE_FIELDS,
  PRODUCT_LISTING_REDUCE_FIELDS,
} from "constants/reduceFields";
import { DEFAULT_FALLBACK_LANGUAGE } from "i18n/constants";
import { Language, LanguageCode } from "i18n/types";
import get from "lodash/get";
import set from "lodash/set";
import lowerCase from "lodash/lowerCase";
import { getAllUrl } from "services/allPage.service";
import { Announcement } from "types/Annoucement";
import { StackData } from "types/Page.interface";
import { default as Stack } from "../contentstack-sdk/index";
import { getConfiguration } from "./configuration.service";
import * as fs from "fs";


const CANONICAL_URL_FIELD_NAME = "global_field.seo.canonical_url";

export async function getPageByURL(
  url: string,
  locale: string,
  referenceFieldPath: string[],
  contentTypeUid: string
) {
  const response: any = await Stack.getInstance().getEntryByField({
    contentTypeUid: contentTypeUid,
    fieldName: CANONICAL_URL_FIELD_NAME,
    fieldValues: [`/${url}`, `/${url}/`, `${url}/`, `${url}`],
    jsonRtePath: undefined,
    referenceFieldPath,
    locale,
  });

  return response[0] as any;
}

export const getAllEntries = async (
  locale: string,
  contentTypeUid: string,
  referenceFieldPath?: string[]
): Promise<any> => {
  const response: any = (await Stack.getInstance().getEntries({
    contentTypeUid: contentTypeUid,
    locale: locale,
    referenceFieldPath: referenceFieldPath,
    jsonRtePath: undefined,
  })) as any;
  return response[0] as any;
};

export const getAllEntryUrls = async (
  contentTypeUid: string,
  tags: string[],
  locale: string
): Promise<any> => {
  const response: any = await Stack.getInstance().getAllEntryUrls({
    contentTypeUid: contentTypeUid,
    tags,
    locale,
  });

  return response[0] as any;
};

export const getRemovedFields = async (): Promise<any> => {
  const response: any = await Stack.getInstance().getRemovedFields();

  return response?.[0] as any;
};

export const getEntryByUid = async (
  entryUid: string,
  locale: string,
  contentTypeUid?: string,
  referenceFieldPath?: string[]
): Promise<any> => {
  if (!contentTypeUid) return;

  let reduceFields: string[] = [];

  let clonedfields =
    referenceFieldPath?.filter((e) => {
      PRODUCT_DETAILS_REDUCE_FIELDS.forEach((ref: string) => {
        if (ref.includes(e)) {
          reduceFields.push(e);
        }
      });

      return (
        !e.includes("body.recommended_products.products.reference") &&
        !e.startsWith(
          "body.dynamic_card_list_block.items.body.dynamic_card_list_block"
        )
      );
    }) || [];

  clonedfields = [...clonedfields, ...reduceFields];

  if (contentTypeUid === ContentTypeUid.PRODUCT_LISTING) {
    const productReduceFields: string[] = [];

    clonedfields =
      referenceFieldPath?.filter((e) => {
        PRODUCT_LISTING_REDUCE_FIELDS.forEach((ref: string) => {
          if (ref.includes(e)) {
            productReduceFields.push(e);
          }
        });

        return !e.includes("body.products_section.products");
      }) || [];
    clonedfields = [
      ...clonedfields,
      "body.products_section.products",
      ...productReduceFields,
    ];
  }

  const response: any = (await Stack.getInstance().getEntryByUid({
    contentTypeUid: contentTypeUid,
    entryUid: entryUid,
    locale: locale,
    referenceFieldPath: clonedfields,
    jsonRtePath: undefined,
  })) as any;

  return response[0] as any;
};

export type ComponentMapping = IPageConfig[];

export const getComponentMapping = async (
  locale: string
): Promise<ComponentMapping> => {
  const response: any = (await Stack.getInstance().getEntryByUid({
    contentTypeUid: "component_mapping",
    entryUid: getConfiguration(
      "NEXT_PUBLIC_CONTENTSTACK_COMPONENT_MAPPING",
      process.env.NEXT_PUBLIC_CONTENTSTACK_COMPONENT_MAPPING
    ),
    locale,
    referenceFieldPath: undefined,
    jsonRtePath: undefined,
  })) as any;

  return response?.[0]?.mapping;
};

export const getErrorPage400Data = async (locale: string): Promise<any> => {
  const response: any = await Stack.getInstance().getEntryByUid({
    contentTypeUid: "error_page",
    entryUid: getConfiguration(
      "NEXT_PUBLIC_CONTENTSTACK_ERROR_PAGE_400_ENTRY_ID",
      process.env.NEXT_PUBLIC_CONTENTSTACK_ERROR_PAGE_400_ENTRY_ID
    ),
    locale,
    referenceFieldPath: undefined,
    jsonRtePath: undefined,
  });

  return response?.[0];
};

export const getEntryByUidByEachReference = async (
  entryUid: string,
  locale: string,
  contentTypeUid: string,
  referenceFieldPath: string[]
) => {
  const response: any = (await Stack.getInstance().getEntryByUidByEachReference(
    {
      entryUid,
      contentTypeUid,
      referenceFieldPath,
      locale,
    }
  )) as any;

  return response;
};
export const getStackData = async () => {
  const stackData = await Stack.getStackData();
  const languageMap = buildLanguageMap(stackData?.i18n?.supportedLocales || []);
  const defaultLanguage =
    Object.entries(languageMap).find(
      ([_, language]) =>
        lowerCase(language.locale) === lowerCase(stackData?.i18n?.defaultLocale)
    )?.[1] || DEFAULT_FALLBACK_LANGUAGE;
  return {
    stackName: stackData.stackName,
    languages: languageMap,
    defaultLanguage,
  } as StackData;
};

const buildLanguageMap = (
  supportedLocales: { locale: string; displayName: string }[]
): Record<LanguageCode, Language> => {
  return Object.fromEntries(
    supportedLocales?.map((locale) => {
      const [languageCode, _] = locale.locale.split("-");
      return [
        languageCode,
        {
          languageCode,
          locale: locale.locale,
          displayName: locale.displayName,
        },
      ];
    })
  );
};

export const generateRobotsTxt = (content: string) => {
  if (fs.existsSync("./public/robots.txt")) {
    return;
  }

  fs.writeFile("./public/robots.txt", content, { flag: "w" }, (err) => {
    if (err) {
      console.log(`[Robots.txt] Save failed!`);
      console.error(err);
    }
    console.log(`[Robots.txt] Saved!`);
  });
};

export const getSiteSettings = async (
  locale: string,
  stackData: StackData,
  referenceFields?: string[]
): Promise<any> => {
  try {
    const response: any = (await Stack.getInstance().getEntries({
      contentTypeUid: "site_settings",
      locale: locale,
      referenceFieldPath: referenceFields,
      jsonRtePath: undefined,
    })) as any;
    const siteSettings = get(response, "[0].[0]", {});
    const enquiryReferences = get(
      siteSettings,
      "error_pages.footer.0.general_enquiry_reference",
      []
    );

    if (enquiryReferences && enquiryReferences.length > 0) {
      const data = await Promise.all(
        enquiryReferences.map(async ({ uid, _content_type_uid }: any) =>
          Stack.getInstance()
            .getEntryByUid({
              contentTypeUid: _content_type_uid,
              entryUid: uid,
              locale,
              referenceFieldPath: undefined,
              jsonRtePath: undefined,
            })
            .then((resp) => get(resp, "0") || {})
        )
      );
      set(siteSettings, "error_pages.footer.0.general_enquiry_reference", data);
    }

    let validUrls: string[] = [];
    await Promise.all(
      Object.keys(stackData.languages).map((locale) =>
        getAllUrl([], stackData?.languages?.[locale].locale).then(
          (data: CMSUrlData[]) => {
            const urls = data?.map((d: CMSUrlData) => d?.url || []);

            validUrls = validUrls.concat(
              urls.map((url) =>
                ["/", locale, url, "/"].join("").replace(/\/\//g, "/")
              )
            );
          }
        )
      )
    );
    Object.keys(stackData.languages).forEach((locale) => {
      ["/plancomparison", "/400", "/404", "/500"].forEach((path) =>
        validUrls.push(["/", locale, path, "/"].join(""))
      );
    });
    set(siteSettings, "validUrls", validUrls);

    return siteSettings;
  } catch (error) {
    throw { errors: [{ error }], source: "getSiteSettings" };
  }
};

export const getAnnouncements = async (
  locale: string
): Promise<Announcement[]> => {
  try {
    const response: any = await Stack.getInstance().getEntries({
      contentTypeUid: "oneweb_announcement",
      locale: locale,
      referenceFieldPath: undefined,
      jsonRtePath: undefined,
    });
    return response?.[0] ?? null;
  } catch (error) {
    throw { errors: [{ error }], source: "getAnnouncements" };
  }
};

export const getSplashData = async (locale: string): Promise<SplashData> => {
  try {
    const response: any = await Stack.getInstance().getEntries({
      contentTypeUid: "splash",
      locale: locale,
      referenceFieldPath: undefined,
      jsonRtePath: undefined,
    });

    return get(response, "[0].[0]", {});
  } catch (error) {
    throw { errors: [{ error }], source: "getSplashData" };
  }
};

export const getPlanComparisonPage = async (
  locale: string
): Promise<SplashData> => {
  const response: any = await Stack.getInstance().getEntries({
    contentTypeUid: "plan_comparison",
    locale: locale,
    referenceFieldPath: [
      "global_field.top_navigation",
      "global_field.top_navigation.country_list",
      "global_field.site_footer",
      "global_field.site_footer.country_list",
      "additional_infos",
    ],
    jsonRtePath: undefined,
  });
  const planComparisonData = get(response, "[0].[0]", {});

  if (planComparisonData.enquiry_reference) {
    const data = await Promise.all(
      planComparisonData.enquiry_reference.map(
        async ({ uid, _content_type_uid }: any) =>
          Stack.getInstance()
            .getEntryByUid({
              contentTypeUid: _content_type_uid,
              entryUid: uid,
              locale,
              referenceFieldPath: [
                "modular_blocks.select_province_block.provinces",
                "modular_blocks.appointment_time_block.appointment_time",
                "modular_blocks.appointment_day_block.appointment_day",
                "email_template",
              ],
              jsonRtePath: undefined,
            })
            .then((resp) => get(resp, "0"))
      )
    );
    planComparisonData.enquiry_reference = data;
  }

  return planComparisonData;
};

export const getCurrentEnvironment = (): Environment => {
  const env = getConfiguration(
    "NEXT_PUBLIC_CONTENTSTACK_ENV",
    process.env.NEXT_PUBLIC_CONTENTSTACK_ENV
  );
  return FindEnvironment(env);
};

export const getIsExportEnvironment = (): string => {
  return process.env.NEXT_PUBLIC_IS_EXPORT || "false";
};

export const getRequestUrl = (url: string): string => {
  return url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `${getConfiguration(
        "NEXT_PUBLIC_API_URL",
        process.env.NEXT_PUBLIC_API_URL
      )}/${url}`;
};

export const getRequestHeaders = (
  values?: { key: string; value: string }[]
): AxiosRequestHeaders => {
  const headers =
    values?.reduce((a: any, v) => {
      if (v.key && v.value) {
        a[v.key] = v.value;
      }
      return a;
    }, {}) || {};
  return {
    Authorization: `Basic ${getConfiguration(
      "NEXT_PUBLIC_API_AUTH_BASE64",
      process.env.NEXT_PUBLIC_API_AUTH_BASE64
    )}`,
    ...headers,
  };
};
