import { CircularProgress } from "@material-ui/core";
import { Button, Link } from "@fwd-dep/nextgen-ui-lib";
import { CmsCta, CTAButtonBackground, CTAButtonVariant } from "@types";
import { replaceContentStackURL } from "@utils";
import clsx from "clsx";
import { BrandIcon } from "components/BrandIcon";
import { I18nContextData } from "i18n/context/LanguageContext";
import { useCallback, useMemo } from "react";
import { useI18nContext } from "utils/hooks";
import { resolveAbsoluteUrl } from "utils/route";
import styles from "./CTAButton.module.scss";
import { _dispatchCTAEvent, _getCTAEventName } from "./CTAButton.service";

export interface CTAProps {
  uid: string;
  url?: string;
  file?: {
    url: string;
    title: string;
  };
  disabled?: boolean;
  title: string;
  openInNewTab: boolean;
  icon?: string;
  gaLabel?: string;
  gtmComponentName?: string;
  triggeredEventName?: string;
  variant: CTAButtonVariant;
  background: CTAButtonBackground;
  isLoading?: boolean;
  iconSize?: number;
}

export function transformCTAButton(
  cmsData: CmsCta | undefined,
  i18nContext: I18nContextData
): CTAProps | undefined {
  if (!cmsData) return;
  return {
    uid: cmsData.uid,
    variant: cmsData.variant,
    background: cmsData.background,
    url: resolveAbsoluteUrl(cmsData.button_url, i18nContext),
    file: cmsData.file
      ? {
          url: replaceContentStackURL(cmsData.file?.url),
          title: cmsData.file?.title,
        }
      : undefined,
    disabled: false,
    title: cmsData.button_title,
    icon: cmsData.icon,
    openInNewTab: cmsData.open_in_new_tab,
    gaLabel: cmsData.ga_label,
    gtmComponentName: cmsData.gtm_component_name || `CTAButton-${cmsData.uid}`,
    triggeredEventName: cmsData.triggered_event_name,
  };
}

export const useCTAProps = (
  cmsData: CmsCta | undefined
): CTAProps | undefined => {
  const i18nContext = useI18nContext();

  return useMemo(
    () => transformCTAButton(cmsData, i18nContext),
    [cmsData, i18nContext]
  );
};

const CTAButton: React.FC<CTAProps> = (props) => {
  const onClickHandler = useCallback(
    (event) => {
      if (props.url || props.file) return;
      event.preventDefault();
      event.stopPropagation();

      _dispatchCTAEvent(props.uid, props.triggeredEventName);
    },
    [props.file, props.triggeredEventName, props.uid, props.url]
  );

  const buttonProps = useMemo(() => {
    const r: any = {};
    if (props.variant === CTAButtonVariant.Secondary) {
      r.outlined = true;
      r.disableFocusRipple = true;
      r.color = "white";
    } else if (props.variant === CTAButtonVariant.Primary) {
      if (props.background === CTAButtonBackground.White) {
        r.color = "orange";
      } else {
        r.color = "white";
      }
    }

    return r;
  }, [props.background, props.variant]);

  return (
    <div
      className={clsx(styles.CTAButton, {
        [styles["CTAButton--disabled"]]: props.disabled || props.isLoading,
      })}
    >
      <Button
        disabled={props.disabled}
        variant={props.variant}
        onClick={onClickHandler}
        type="button"
        href={props.url}
        className={clsx({
          [styles["CTAButton--secondary"]]:
            props.variant === CTAButtonVariant.Secondary,
          [styles["CTAButton--secondary-orange-bg"]]:
            props.variant === CTAButtonVariant.Secondary &&
            props.background === CTAButtonBackground.Orange,
          [styles["CTAButton--secondary-white-bg"]]:
            props.variant === CTAButtonVariant.Secondary &&
            props.background === CTAButtonBackground.White,
        })}
        {...buttonProps}
      >
        {props.isLoading ? (
          <CircularProgress
            className={styles.CTAButton__Spinner}
            color="inherit"
          />
        ) : (
          <div className={styles.CTAInner}>
            {props.icon && (
              <BrandIcon icon={props.icon} className={styles.CTAIcon} />
            )}
            {props.title}
          </div>
        )}
      </Button>
    </div>
  );
};

export default CTAButton;
