import lowerCase from "lodash/lowerCase";

import { DisclosurePopupData } from "types/DisclosurePopup.interface";

export enum ContentBlockImagePlacement {
  Left = "Left",
  Right = "Right",
}

export function transformCSImagePlacementValue(
  imagePlacementValue: string = "left"
) {
  return lowerCase(imagePlacementValue) === "left"
    ? ContentBlockImagePlacement.Left
    : ContentBlockImagePlacement.Right;
}

export interface ContentBlockCTA {
  title: string;
  url: string;
  file?: { url?: string };
  iconName: string;
  iconColor?: string;
  normalColor?: {
    background: string;
    foreground: string;
    border: string;
  };
  activeColor?: {
    background: string;
    foreground: string;
    border: string;
  };
  ctaType: ContentBlockCTAType;
  openInNewTab: boolean;
  disclosure_popup?: DisclosurePopupData[];
  product_plan_section?: any;
  modal?: {
    title: string;
    description: string;
  };
  ga_label: string;
  display_social_media_share_options?: boolean;
}

export interface FeatureTag {
  key: string;
  value: string;
}

export enum ContentBlockCTAType {
  SimpleLink = "SimpleLink",
  Button = "Primary",
  iOSDownloadButton = "iOS",
  AndroidDownloadButton = "Android",
}

export enum ContentType {
  featured = "featured",
  default = "default",
  infographic = "infographic",
  html = "html",
  productEnquiry = "product-enquiry",
  generalEnquiry = "general-enquiry",
  recruitmentEnquiry = "recruitment-enquiry",
  form = "form",
  productEnquiryNoName = "product-form-no-name",
  contactEnquiry = "contact-enquiry",
  mindstrengthEnquiry = "mindstrength-enquiry",
}

export interface ContentBlockData {
  index?: number;
  title: string;
  subtitle?: {
    title?: string;
    icon?: string;
  };
  content: string;
  isHorizontalVertical?: boolean;
  isHideStepper?: boolean;
  externalScript: string;
  spacing: {
    padding_top: string; // e.g. 10px
    padding_bottom: string;
  };
  image?: string;
  imageAlt?: string;
  imageInfographic?: string;
  imageInfographicAlt?: string;
  colors?: {
    parent_background: string;
    background: string;
    foreground: string;
  };
  type: ContentType;
  callToActionLinks?: ContentBlockCTA[];
  imagePlacement?: ContentBlockImagePlacement;
  divider?: boolean;
  featuredTags?: FeatureTag[];
  availableText?: string;
  purchaseMethod?: string[];
  brightcove_video?: {
    video_id?: string;
    player_id?: string;
    brightcove_account_id?: string;
  }[];
  limitWidthContent: boolean;
  youtube_video_id?: string;
  template_category?: string;
  disclosurePopupProps?: any;
  product_plan_section?: any;
  ctas?: any[];
  anchoringId?: string;
}

export interface ContentStackResource {
  content_type: string;
  created_at: string;
  created_by: string;
  file_size: string;
  filename: string;
  is_dir: boolean;
  parent_uid: null | string;
  publish_details: Object;
  tags: string[];
  title: string;
  uid: string;
  updated_at: string;
  updated_by: string;
  url: string;
}

export default ContentBlockData;
