import { CSReminderBlock } from "components/Reminder";
import { CSCardList } from "components/CardList";
import { CSDownloadList } from "components/DownloadList";

export interface CmsBlock {
  uid: string;
  _content_type_uid: string;
  _version: string;
}

export interface CmsReferenceBlock {
  reference: any[];
  reference_2: any[];
  reference_3: any[];
  reference_4: any[];
  reference_5: any[];
}

export const REFERENCE_BLOCK_COMPONENT_MAP = {
  ref_usps_block: CSCardList,
  download_list: CSDownloadList,
  usp_reminder_block: CSReminderBlock,
};
