import { PopupData, PopupInstanceData, PopupType } from "./Popup.type";
import { Dialog } from "@fwd-dep/nextgen-ui-lib";
import { FC, useEffect, useState } from "react";
import { dismissPopup, onDismissPopup } from "./Popup.service";
import styles from "./Popup.module.scss";
import { ReferenceBlock } from "shared-components/ReferenceBlock";
import CTAButton from "components/CTAButton";
import DOMPurify from "isomorphic-dompurify";
import clsx from "clsx";
import { registerCTAHandler } from "components/CTAButton/CTAButton.service";

const DialogBody: FC<PopupInstanceData> = (props) => {
  return (
    <div className={styles.DialogBody}>
      <h3 className={styles.DialogTitle}>{props.title}</h3>
      <div
        className={styles.DialogDescription}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.description),
        }}
      />
      {(props.primaryButton || props.secondaryButton) && (
        <div className={styles.DialogButtonWrapper}>
          {props.primaryButton && <CTAButton {...props.primaryButton} triggeredEventName={`popup:primary-button:${props.uid}`}/>}
          {props.secondaryButton && <CTAButton {...props.secondaryButton} triggeredEventName={`popup:secondary-button:${props.uid}`}/>}
        </div>
      )}
    </div>
  );
};

const PopupBody: FC<PopupData> = (props) => {
  return (
    <div>
      {props.blocks && (
        <div>
          {props.blocks.map((b) => (
            <ReferenceBlock block={b.reference_block} />
          ))}
        </div>
      )}
    </div>
  );
};

export const Popup: FC<PopupInstanceData> = (props) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    if (!props.buttonsHandler) return;
    const removeHandlers = [] as Function[];
    Object.entries(props.buttonsHandler).forEach(
      ([buttonType, buttonHandler]) => {
        const handler = (ev: Event) => {
          ev.preventDefault();
          buttonHandler(props);
        };
        removeHandlers.push(registerCTAHandler("", handler, `popup:${buttonType}-button:${props.uid}`));
      }
    );

    return () => {
      if (!props.buttonsHandler) return;

      Object.values(removeHandlers).forEach((removeHandler) => {
        removeHandler();
      });
    };
  }, [props]);

  useEffect(() => {
    const removeListener = onDismissPopup(props.popupId, () => {
      setShow(false);
    });

    return () => removeListener();
  });

  return (
    <Dialog
      open={show}
      onClose={() => dismissPopup(props.popupId)}
      fullScreen={props.type === PopupType.Popup}
      md={20}
      lg={20}
      mobileFluid
      className={clsx({
        [styles.DialogPopup]: props.type === PopupType.Dialog,
        [styles.NormalPopup]: props.type === PopupType.Popup,
      })}
    >
      {props.type === PopupType.Dialog && <DialogBody {...props} />}
      {props.type === PopupType.Popup && <PopupBody {...props} />}
    </Dialog>
  );
};
