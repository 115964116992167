import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { DEFAULT_FALLBACK_LANGUAGE } from "i18n/constants";
import { NextPageContext } from "next";
import "../@fwd-dep/nextgen-ui-lib/dist/esm/assets/ui-lib-1b66bfd7.css";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/globals.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LiveChat from "utils/LiveChat";
import { useEffect, useState } from "react";
import gtmUtils from "utils/gtm";
import Head from "next/head";
import DOMPurify from "isomorphic-dompurify";
import commonConfig from "config/common";
import HeaderMetadata from "components/HeaderMetadata";
import { getWindow } from "@utils";
import { AppEncryptionProvider } from "context/AppEncryptionContext";
import { buildFontFamilyStyle } from "utils/styles";
import { useIsHydrating } from "hooks/components/useHydrating";
import { PopupHolder } from "shared-components/Popup/PopupHolder";

interface Props extends AppProps {
  supportedLanguages: Record<
    string,
    { locale: string; languageCode: string; displayName: string }
  >;
  ctx: NextPageContext;
}

function App({ Component, pageProps }: Props) {
  const { locale } = useRouter();
  const isHydrating = useIsHydrating();
  const [googleScripts, setGoogleScripts] = useState({
    gtmScripts: "",
    optScripts: "",
    antiFlickerEnabled: false,
  });

  const onReloadPage = () => {
    getWindow()?.location?.reload();
  };

  useEffect(() => {
    getWindow()?.addEventListener("popstate", () => onReloadPage);
    return () => getWindow()?.removeEventListener("popstate", onReloadPage);
  }, []);

  const handlePushEvent = () => {
    gtmUtils.pushEvent({
      event: "print",
    });
  };

  useEffect(() => {
    if (window !== undefined) {
      LiveChat();
      getWindow()?.addEventListener("afterprint", handlePushEvent);
    }
    return () =>
      getWindow()?.removeEventListener("afterprint", handlePushEvent);
  });

  useEffect(() => {
    let newGoogleScripts = {
      antiFlickerEnabled: false,
      gtmScripts: "",
      optScripts: "",
    };

    newGoogleScripts.antiFlickerEnabled =
      (pageProps as any)?.data?.antiFlickerEnabled || false;
    newGoogleScripts.optScripts =
      (pageProps as any)?.siteSettings?.embeded_scripts?.google_optimize || "";

    setGoogleScripts(newGoogleScripts);
  }, [
    (pageProps as any)?.data,
    (pageProps as any)?.siteSettings?.embeded_scripts?.google_optimize,
    (pageProps as any)?.siteSettings?.embeded_scripts?.google_tag_manager,
  ]);

  const seoData = (pageProps as any)?.data?.layout?.find(
    (item: any) => item.nameComponent === "NextHead"
  )?.dataComponent;

  const supportedLanguages = (pageProps as any)?.supportedLanguages || null;

  const splitedGtmScript =
    (
      pageProps as any
    )?.siteSettings?.embeded_scripts?.google_tag_manager?.split("GTM-") || [];
  const gtmId =
    splitedGtmScript?.[splitedGtmScript?.length - 1]?.replace(
      /[^a-zA-Z0-9]/g,
      ""
    ) || null;

  const siteSettings = (pageProps as any)?.siteSettings;

  return (
    <>
      {gtmId && (
        // Google Tag Manager (noscript)
        <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=GTM-${gtmId}`}
          height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe></noscript>
      )}

      {seoData && supportedLanguages && (
        <HeaderMetadata
          data={seoData}
          supportedLanguages={supportedLanguages}
        />
      )}
      <Head>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: buildFontFamilyStyle(
              siteSettings?.font_family,
              siteSettings?.font_face
            ),
          }}
        />
        {/* Google Tag Manager - Global base code */}
        { (pageProps as any)?.siteSettings?.embeded_scripts
          ?.google_tag_manager && (
            <>
              <script
                dangerouslySetInnerHTML={{
                  __html: (pageProps as any)?.siteSettings.embeded_scripts
                    .google_tag_manager,
                }}
                defer
              />
              {gtmId && (
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'GTM-${gtmId}', {
                    page_path: window.location.pathname,
                  });
                  `,
                  }}
                />
              )}
            </>
          )}
        {googleScripts.optScripts && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(googleScripts.optScripts),
              }}
            />
          </>
        )}
        <script type="text/javascript" src="/sdk.js"></script>
      </Head>
        <div>
          <Component {...pageProps} />
          <PopupHolder/>
        </div>
    </>
  );
}

function CustomApp(props: any) {
  return (
    <AppEncryptionProvider {...props}>
      <App {...props} />
    </AppEncryptionProvider>
  );
}

export default CustomApp;
