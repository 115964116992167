export interface CMSUrlData {
  isHideFromXML: boolean;
  url: string;
  lastMod: string;
}

export interface MapItem {
  key: string;
  value: Environment;
}

export interface IPageConfig {
  contentTypeName: string;
  componentName: string;
  componentVersion: string;
  apiEndpoint: string;
}

export interface QueryPageLayout {
  uid: any;
  contentTypeUid: string;
  includes: string[] | null;
  locale: string;
}

//export const PAGE_TAGS_LIST = ["poc"];
//
// Temporary don't query by tag poc
export const ARRAY_ERRORS_CODE = [414, 431];

export const PAGE_TAGS_LIST = [];

export enum Environment {
  DEV = "dev",
  SIT = "sit",
  UAT = "uat",
  PROD = "prod",
}

export const MapEnvironment = [
  {
    key: "dev",
    value: Environment.DEV,
  },
  {
    key: "sit",
    value: Environment.SIT,
  },
  {
    key: "uat",
    value: Environment.UAT,
  },
  {
    key: "prod",
    value: Environment.PROD,
  },
];

export const FindEnvironment = (env: string): Environment => {
  const find = MapEnvironment.find((item: MapItem) => {
    return env.includes(item.key);
  });

  return find ? find.value : Environment.DEV;
};

export const getTimeSiteMap = (lastMod: string | null): string => {
  return lastMod
    ? new Date(lastMod).toISOString().split("T")[0]
    : new Date().toISOString().split("T")[0];
};


export enum PageStorage {
  BaseStorage = "baseStorage"
}

export enum PageClaims {
  ClaimDetails = "claimDetails"
}