import { Maybe } from "@types";
import { replaceContentStackURL } from "@utils";
import get from "lodash/get";
import {
  resolveAbsoluteUrlInParagraphServer,
  resolveAbsoluteUrlServer,
} from "utils/route";
import {
  BannerDataCmsProps,
  ReviewCarouselBannerCmsDataProps,
  ReviewCarouselBannerCmsProps,
  ReviewCarouselBannerDataProps,
} from "../dtos/index.type";

export const getReviewCarouselBannerData = (
  cmsData: ReviewCarouselBannerCmsProps,
  languageCode: string,
  numberOfLang: number
): ReviewCarouselBannerDataProps => {
  const data: Maybe<ReviewCarouselBannerCmsDataProps> =
    cmsData?.reference_5?.[0];

  if (!data) {
    return {
      uid: "",
      setting: {
        autoPlay: false,
        autoPlaySpeed: 10000,
        loop: false,
      },
      bannerData: [],
    };
  }

  return {
    uid: get(data, "uid", ""),
    setting: {
      autoPlay: get(data, "setting.autoplay", false),
      autoPlaySpeed: get(data, "setting.autoplay_speed", 10000),
      loop: get(data, "setting.loop", false),
    },
    bannerData: get(data, "banner_data", []).map((b: BannerDataCmsProps) => ({
      reviewData: {
        title: get(b, "review_data.title", ""),
        score: get(b, "review_data.score", 0),
        scoreDescription: get(b, "review_data.score_description", ""),
        icon: replaceContentStackURL(get(b, "review_data.icon", "")),
        name: get(b, "review_data.name", ""),
      },
      desktopImage: {
        url: replaceContentStackURL(get(b, "desktop_image.url", "")),
      },
      mobileImage: {
        url: replaceContentStackURL(get(b, "mobile_image.url", "")),
      },
      title: get(b, "title", ""),
      styledTitle: resolveAbsoluteUrlInParagraphServer(
        get(b, "styled_title", ""),
        languageCode,
        numberOfLang
      ),
      description: get(b, "description", ""),
      ctaButton: {
        url: resolveAbsoluteUrlServer(
          get(b, "cta_button.url", ""),
          languageCode,
          numberOfLang
        ),
        title: get(b, "cta_button.title", ""),
        openInNewTab: get(b, "cta_button.open_in_new_tab", true),
        gaLabel: get(b, "cta_button.ga_label", ""),
      },
    })),
  };
};
