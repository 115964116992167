import { contentTypeTemplates } from "constants/contentTypeTemplates";
import { ContentTypeUid } from "constants/contentTypeUid.enum";
import { ARRAY_ERRORS_CODE, CMSUrlData } from "constants/pages.constants";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import merge from "lodash/merge";
import uniq from "lodash/uniq";

import {
  getAllEntryUrls,
  getComponentMapping,
  getEntryByUid,
  getEntryByUidByEachReference,
  getPageByURL,
  getRemovedFields,
} from "page-services/helper.service";
import { getPageData } from "page-services/index.service";
import { IPageLayout, IUrlInfo } from "types/Page.interface";

const STUB_LAYOUT_DATA = {
  uid: "",
  url: "",
  layout: [],
  title: "",
  antiFlickerEnabled: false,
};

export async function getPageLayoutDataByURL(
  url: string,
  locale: string,
  numberOfLang: number = 1,
): Promise<IPageLayout> {
  let arrRequests: any = [];
  contentTypeTemplates.forEach((contentTypeUid) => {
    arrRequests.push(getPageByURL(url, locale, [], contentTypeUid));
  });
  const pages = await Promise.all(arrRequests);

  let idx = pages.findIndex((p) => p != null);

  if (idx >= 0 && !pages[idx]) return STUB_LAYOUT_DATA;
  const componentMapping = await getComponentMapping(locale);

  const layoutData = await getPages(
    locale,
    [pages[idx]],
    componentMapping,
    0,
    5,
    contentTypeTemplates[idx],
    numberOfLang,
  );

  return (
    layoutData?.[0] || STUB_LAYOUT_DATA
  );
}

export const getAllUrl = async (
  tags: string[],
  locale: string
): Promise<CMSUrlData[]> => {
  let arrRequests: any = [];
  contentTypeTemplates.forEach((contentTypeUid) => {
    arrRequests.push(getAllEntryUrls(contentTypeUid, tags, locale));
  });
  const response = await Promise.allSettled(arrRequests);

  let result: any = [];

  let errors: { error: any }[] = [];
  response.map((d: any, _index: number) => {
    if (d.status === "fulfilled") {
      if (d.value && d.value.length) {
        result = [...result, ...d.value];
      }
    }

    if (d.status === "rejected") {
      errors.push({
        error: d.reason,
      });
    }
  });

  if (errors.length > 0) {
    throw { errors, source: "getAllUrl" };
  }

  const allUrls = result
    .filter((data: any) => !data?.global_field?.external_page)
    .map((data: any) => {
      return {
        url: data?.global_field?.seo?.canonical_url.trim(),
        isHideFromXML: data?.global_field?.seo?.is_hide_from_xml,
        lastMod: data?.updated_at,
      };
    });

  return await getUrls(allUrls);
};

export const getPages = async (
  locale: string,
  allPage: any[],
  componentMapping: any,
  depth: number = 0,
  maxDepth: number = 2,
  contentTypeUid: string,
  numberOfLang: number,
): Promise<IPageLayout[]> => {
  const CSRemovedFields = await getRemovedFields();
  let removedFields: any[] = [];

  if (CSRemovedFields) {
    removedFields = isArray(CSRemovedFields.removed_fields)
      ? CSRemovedFields.removed_fields.filter((e: any) => e && e.length > 0)
      : CSRemovedFields.removed_fields;
  }
  if (contentTypeUid === ContentTypeUid.ARTICLE_LISTING) {
    maxDepth = 2;
  }

  if (
    contentTypeUid === ContentTypeUid.PRODUCT_LISTING ||
    contentTypeUid === ContentTypeUid.PRODUCT_DETAILS
  ) {
    maxDepth = 3;
  }

  while (depth < maxDepth && allPage.some((page) => needResolveRef(page))) {
    const queryPageLayout = allPage?.map((page: any) => {
      if (page?.uid) {
        const includes = getTreePaths(page, removedFields);

        return getEntryByUid(page.uid, locale, contentTypeUid, uniq(includes));
      }
    });

    const result = await Promise.allSettled(queryPageLayout);
    const queryPageLayoutOverload: any[] = [];
    let errors: { error: any; uid: string; contentTypeUid: string }[] = [];

    let pageLayoutList = result.map((d: any, index: number) => {
      if (d.status === "fulfilled") {
        return merge(d.value, allPage[index]);
      }

      if (d.status === "rejected") {
        if (ARRAY_ERRORS_CODE.includes(d.reason.status)) {
          queryPageLayoutOverload.push({
            query: getEntryByUidByEachReference(
              allPage[index].uid,
              locale,
              contentTypeUid,
              uniq(getTreePaths(allPage[index], removedFields))
            ),
            index,
            uid: allPage[index].uid,
          });

          console.log(
            ` ${allPage[index].uid} - ${contentTypeUid} has too much references - breaking down`
          );

          return merge({}, allPage[index]);
        } else {
          errors.push({
            error: d.reason,
            uid: allPage[index].uid,
            contentTypeUid,
          });
        }
      }
    });

    if (errors.length > 0) {
      throw { errors, source: "getPages" };
    }

    if (queryPageLayoutOverload && !!queryPageLayoutOverload.length) {
      const result = await Promise.allSettled(
        queryPageLayoutOverload.map((q: any) => q.query)
      );

      const errors: { error: any }[] = [];
      result
        .map((d: any) => {
          if (d.status === "fulfilled") {
            const index = queryPageLayoutOverload.find(
              (q: any) => q.uid === d.value.uid
            )?.index;

            if (index == null || index == undefined) return null;

            return merge(d.value, pageLayoutList[index]);
          }

          if (d.status === "rejected") {
            errors.push({ error: d.reason });
            return null;
          }
        })
        .filter((d: any) => !!d);

      if (errors.length > 0) {
        throw { errors, source: "getPage:queryPageLayoutOverload" };
      }
    }
    allPage = pageLayoutList;
    depth += 1;
  }

  return await Promise.all(
    allPage.map(
      (pageLayout: any): Promise<IPageLayout> =>
        getPageData(pageLayout, componentMapping, locale, numberOfLang)
    )
  );
};

const customGetPages = async (
  allPage: any[],
  depth: number = 0,
  maxDepth: number = 2,
  contentTypeUid: string,
  locale: string = "en-us"
) => {
  const CSRemovedFields = await getRemovedFields();
  let removedFields: any[] = [];

  if (CSRemovedFields) {
    removedFields = isArray(CSRemovedFields.removed_fields)
      ? CSRemovedFields.removed_fields.filter((e: any) => e && e.length > 0)
      : CSRemovedFields.removed_fields;
  }

  const queryPageLayout = allPage.map((page: any) => {
    if (page.uid) {
      const includes = getTreePaths(page, removedFields);

      return getEntryByUid(page.uid, locale, contentTypeUid, uniq(includes));
    }
  });

  const result = await Promise.allSettled(queryPageLayout);

  let pageLayoutList = result.map((d: any, index: number) => {
    if (d.status === "fulfilled") {
      return merge(d.value, allPage[index]);
    }

    if (d.status === "rejected") {
      console.log({ error: d.reason });
      return null;
    }
  });

  if (depth <= maxDepth) {
    const needResolveList = pageLayoutList.filter((page) =>
      needResolveRef(page)
    );

    const resolvedList = await customGetPages(
      needResolveList,
      depth + 1,
      maxDepth,
      contentTypeUid,
      locale
    );

    if (!resolvedList.length) {
      return pageLayoutList;
    }

    pageLayoutList = pageLayoutList.map((p: any) => {
      const find = resolvedList.find((r: any) => r?.uid === p?.uid);
      if (!find) {
        return p;
      }

      return find;
    });
  }

  return pageLayoutList;
};

function needResolveRef(page: any) {
  let needResolve = false;

  let pool = [page];

  while (pool.length > 0) {
    let subject = pool.pop();
    if (!subject) continue;
    if (!isArray(subject) && !isObject(subject)) continue;
    if (isArray(subject)) {
      subject.forEach((entry) => pool.push(entry));
      continue;
    } else if (
      isObject(subject) &&
      Object.keys(subject).length === 2 &&
      (subject as any)["uid"] &&
      (subject as any)["_content_type_uid"]
    ) {
      needResolve = true;
      break;
    }

    Object.values(subject).forEach((entry) => {
      pool.push(entry);
    });
  }

  return needResolve;
}

export const getUrls = async (allUrls: IUrlInfo[]): Promise<CMSUrlData[]> => {
  return (
    allUrls
      //.filter((data: any) => !data?.isHideFromXML)
      .map((data: any): CMSUrlData => {
        return {
          isHideFromXML: data?.isHideFromXML,
          url: validSplashPath(data?.url),
          lastMod: data?.lastMod,
        };
      })
  );
};

export const getTreePaths = function (tr: any, removedFields: string[]) {
  let paths: string[] = [];
  let pool = [[tr, ""]];

  while (pool.length > 0) {
    const node = pool.pop();
    if (!node || !node[0]) continue;
    // if (str.endsWith("reference")) return;

    const branch = node[0];
    const str = node[1];
    Object.keys(branch).forEach(function (key) {
      if (isArray(branch[key]) || isObject(branch[key])) {
        if (isArray(branch[key]) && branch[key].length) {
          if (
            Object.keys(branch[key][0]).length === 2 &&
            branch[key][0]["uid"] &&
            branch[key][0]["_content_type_uid"]
          ) {
            if (!isNumeric(key)) paths.push(str ? str + "." + key : key);
            return;
          }

          branch[key].forEach((b: any) =>
            pool.push([b, str ? str + "." + key : key])
          );
        } else if (
          isObject(branch[key]) &&
          branch[key]["uid"] &&
          branch[key]["_content_type_uid"]
        ) {
          if (!isNumeric(key)) paths.push(str ? str + "." + key : key);
        } else {
          if (!isNumeric(key)) {
            pool.push([branch[key], str ? str + "." + key : key]);
          } else {
            pool.push([branch[key], str]);
          }
        }
      }
    });
  }

  return paths.filter((e) => {
    let isIncluded = false;

    for (let i = 0; i < removedFields.length; i++) {
      if (e.includes(removedFields[i])) {
        isIncluded = true;
        break;
      }
    }
    return !isIncluded;
  });
};

function isNumeric(value: any) {
  return /^-?\d+$/.test(value);
}

export const validSplashPath = function (path: string) {
  if (path && path.length > 1) {
    path = path.charAt(0) === "/" ? path : `/${path}`;
    path = path.charAt(path.length - 1) === "/" ? path.slice(0, -1) : path;
  }
  return path;
};

export async function getPageDataByURL(url: string, locale: string) {
  const arrRequests = contentTypeTemplates.map((contentTypeUid) =>
    getPageByURL(url, locale, [], contentTypeUid)
  );
  const pages = await Promise.all(arrRequests);
  const idx = pages.findIndex((p) => !!p);
  return idx >= 0 ? pages[idx] : null;
}

export async function getBreadcrumbPaths(url: string, locale: string) {
  const segments = url.split("/").filter((segment) => segment !== "");
  const pathList = segments.map(
    (_, index) => `/${segments.slice(0, index + 1).join("/")}`
  );
  if (url.endsWith("/undefined")) {
    pathList.push(`${url}/`);
  }

  const pages = await Promise.all(
    pathList.map((path) => getPageDataByURL(path, locale))
  );
  const breadcrumbs = pages.map((page, idx) => ({
    position: idx + 2,
    name: page?.global_field?.page_title || "",
    url: page?.global_field?.seo?.canonical_url || "",
  }));

  return breadcrumbs;
}
