import { NamedIcon } from "@fwd-dep/nextgen-ui-lib";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { isImageURL } from "utils/iconUtils";
interface BrandIconProps {
  icon: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  className?: string;
}

const Wrapper = styled.div``;

export const BrandIcon: React.FC<BrandIconProps> = (props) => {
  return isImageURL(props.icon) ? (
    <Wrapper
      style={{
        width: props.width || "26px",
        height: props.height || "28px",
        ...props.style,
      }}
      className={props.className}
    >
      <img
        src={props.icon}
        width={props.width || "26px"}
        height={props.height || "28px"}
      />
    </Wrapper>
  ) : (
    <Wrapper style={props.style} className={props.className}>
      <NamedIcon icon={props.icon} />
    </Wrapper>
  );
};
