export default {
  green: '#03824F',
  darkGreen: '#183028',
  grey: '#dbdfe1',
  grey2: '#636566',
  grey3: '#8B8E8F',
  grey4: '#B3B6B8',
  fwdMinGrey: '#757575',
  fwdGrey50: '#EDEFF0',
  secondaryColorFwdGrey: '#DBDFE1',
  supportingColorFwdGrey: '#636566',
  orange: '#fae4d3',
  darkOrange: '#b74701',
  fwdWhite: '#FFFFFF',
  fwdWhite20: '#FFFFFF20',
  orangeLighter: '#FEF9F4',
  fwdOrange: '#e87722',
  fwdOrangeLight: '#FEF9F4',
  fwdLightOrange: '#FEF9F4',
  alertRed: '#b30909',
  alertGreenLight: '#f2f9f6',
  alertGreen: '#03824f',
  white1: '#c4c4c4',
  greenDiscountCountDown: '#6ECEB2',
  orangeSoft: '#FDF4ED',
  orange50: "#FFF0E4",
  fwdOrange50: "#F3BB90",
  secondaryFwdOrange: "#e8772233",
  white: "#FFFFFF",
  orange20: "#FAE4D3",
};
