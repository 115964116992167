import { Portal } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { v4 } from "uuid";
import { usePopupInternal } from "./Popup.service";

export const PopupHolder = () => {
  const { popups, template } = usePopupInternal();

  return (
    <>
      {popups.map(({ id, templateId, data }) => {
        const Component = template[templateId];
        return <Component {...data} key={id} popupId={id} />;
      })}
    </>
  );
};
